@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  .scrollbar::-webkit-scrollbar-track {
    @apply rounded-full;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    @apply rounded-full bg-[#EBEBE6];
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-slate-500;
  }
  .scrollbar1::-webkit-scrollbar {
    @apply w-2 h-5;
  }

  .scrollbar1::-webkit-scrollbar-track {
    @apply rounded-full;
  }

  .scrollbar1::-webkit-scrollbar-thumb {
    @apply rounded-full bg-[#EBEBE6];
  }

  /* Global scrollbar styling */
  *::-webkit-scrollbar {
    width: 8px; /* Change scrollbar width */
    height: 8px; /* Change scrollbar height for horizontal */
  }

  *::-webkit-scrollbar-track {
    @apply rounded-full bg-gray-200; /* Style scrollbar track */
  }

  *::-webkit-scrollbar-thumb {
    @apply rounded-full bg-[#EBEBE6]; /* Style scrollbar thumb */
  }

  *::-webkit-scrollbar-thumb:hover {
    @apply bg-slate-500; /* Thumb on hover */
  }

  /* Optional: For Firefox support */
  * {
    scrollbar-width: thin; /* Firefox scrollbar width */
    scrollbar-color: #cdcdc7 #f0f0f0; /* Thumb and track color in Firefox */
  }

  /* .scrollbar1::-webkit-scrollbar-thumb:hover {
    @apply bg-slate-500;
  } */
}



/* @font-face {
  font-family: "Lota";
  src: url("../public/FirmFox Fonts Select/Lota Grotesque Alt 1 Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lota";
  src: url("../public/FirmFox Fonts Select/Lota Grotesque Alt 1 Bold.otf")
    format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lota";
  src: url("../public/New Font/lota_grotesque_alt_1_regular-webfont.woff2")
      format("woff2"),
    url("../public/New Font/lota_grotesque_alt_1_regular-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lota";
  src: url("../public/New Font/lota_grotesque_alt_1_bold-webfont.woff")
      format("woff");
   font-weight: bold;
  font-style: normal;
} */

@font-face {
  font-family: 'Lota';
  src: url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Light/font.woff2')
      format('woff2'),
    url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Light/font.woff')
      format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lota';
  src: url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Thin/font.woff2')
      format('woff2'),
    url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Thin/font.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lota';
  src: url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Regular/font.woff2')
      format('woff2'),
    url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Regular/font.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lota';
  src: url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Bold/font.woff2')
      format('woff2'),
    url('../public/Lota_Grotesque_Webfont_kit/webFonts/LotaGrotesqueAlt1Bold/font.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Lota', sans-serif !important;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebebe6 !important;
}

code {
  font-family: 'Lota', sans-serif !important;
  font-weight: 400;
  font-style: normal;
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

.MuiListItem-root:hover {
  background: none !important;
}

.bullet {
  display: inline-block;
  width: 19px !important;
  height: 19px !important;
  border-radius: 100%;
  background-color: white;
  border: 2px solid rgb(202, 201, 201);
}

.bullet.selected {
  background-color: #f8d442;
  /* border-color: black; */
}

.border {
  border-radius: 3px;
  /* border-color: black; */
  /* border-right: 1px solid;  */
}

.solid {
  /* border: 2px rgb(197, 195, 195) solid; */
  border-radius: 10px;
}
.bg-customGreen {
  background-color: #81ef6f;
}

/* .bullet {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid rgb(202, 201, 201);
  margin-right: 8px;
}

.bullet.selected {
  background-color: #F8D442;
  border-color: #1E1D1B;
}

/* .border {
  border-radius: 3px; 
  border-color: black;
  border-right: 1px solid; 
} */

.markdown > * {
  all: revert;

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1em;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }

  th,
  td {
    padding: 12px 15px;
    border: 1px solid black;
    text-align: left;
  }

  th {
    background-color: black;
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  p {
    line-height: 1.5;
  }
}

.phoneNumber .css-cwfp0z-MuiInputBase-root-MuiOutlinedInput-root {
  max-height: 42px;
  border-radius: 6px !important;
  border: 2px;
}

.password .css-1xbbe6-MuiInputBase-root-MuiOutlinedInput-root {
  max-height: 41px !important;
}

.password.css-1xbbe6-MuiInputBase-root-MuiOutlinedInput-root:focus {
  color: black;
}

