html, body, #root {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-customPurple {
  background-color: #81ef6f;
}

/* .MuiButtonBase-root:hover{
background-color: transparent !important;
} */

.placeholder-up::placeholder {
  transform: translateY(-1.5em);
  transition: transform 0.3s ease;
}

.placeholder-up::placeholder {
  transition: all 0.3s ease-out;
  transform: translateY(-150%);
}

.placeholder-up::-webkit-input-placeholder {
  transition: all 0.3s ease-out;
  transform: translateY(-150%);
}

.placeholder-up::-moz-placeholder {
  transition: all 0.3s ease-out;
  transform: translateY(-150%);
}

.placeholder-up:-ms-input-placeholder {
  transition: all 0.3s ease-out;
  transform: translateY(-150%);
}

.placeholder-up:-moz-placeholder {
  transition: all 0.3s ease-out;
  transform: translateY(-150%);
}

.black-background {
  background-color: black;
  border-radius: 20px;
}


.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mui-divider-custom .css-qywfm8-MuiDivider-wrapper{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.wrapped-capsule{
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis */
  max-width: 280px; /* Set a maximum width */
}

@media screen and (max-width: 640px) {
  .wrapped-capsule{
    width: 100%;
    overflow-x: hidden;
  }  
}

.wrapped-text-custom{
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis */
}

@media screen and (max-width: 1199px) {
  .find-jurisdiction-mobile-container{
    max-height: fit-content !important;
  }  
}

.jurisdiction-upper-container{
  display: flex;
  flex-direction: column;
}

.find-jurisdiction-mobile-container{
  /* height: 400px; */
  overflow-y: auto;
  flex-grow: 1 !important;
}

.error-msg-div{
  margin-top: 5px;
}

.error-msg-text{
  font-size: 14px;
  color:red;
}

.three-btn-row{
  height: 36px !important;
}

@media screen and (max-width:640px) {

  .three-btn-row{
    height: 36px !important;
  }


  
}

@media screen and (max-width: 1280px) {
  .inner-parent-select-js-container{
    max-height: calc(100dvh - 80px - 60px - 115px);
    overflow-y: auto;
  }
}

    .step-line-mobile{
      height: 50px;
    }

    .step-ball-container{
      justify-content: flex-start;
      gap:17px
    }

    .arrow-visibility{
      display: none;
    }

    .step-tracker-container{
      min-height: 60px;
      height: fit-content;
      max-width: 700px;
      margin-top: -15px;
    }

    @media screen and (max-width:820px) {
      .arrow-visibility{
        /* display: block; */

      }

      .step-tracker-container{
        /* margin-left: -10px; */
      }
    }

    /* Hide scrollbar for webkit browsers (Chrome, Safari, Edge) */
    .scrollbar-hidden::-webkit-scrollbar {
      display: none;
    }

    /* For other browsers */
    .scrollbar-hidden {
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */
    }


    /* break point media queries for app step screen's sub containers */

    @media screen and (min-width: 640px) and (max-width:1280px) {

      .confirm-detail-dynamic-container{
        max-height: calc(100dvh - 90px - 32px - 32px - 20px - 60px - 60px);
      }
      
    }

    @media screen and (max-width:1280px ){
      .confirm-detail-dynamic-container{
        height: fit-content;
        max-height: calc(100dvh - 90px - 32px - 32px - 20px - 60px - 60px);
      }      

      .biz-name-info-container{
        height: 100%;
        max-height: 100%;
      }
    }

    @media screen and (min-width: 1280px) {
      .confirm-detail-dynamic-container{
        height: fit-content;
        max-height: calc(100dvh - 90px - 32px - 32px - 20px - 50px);
      }

      .biz-name-info-container{
        max-height: calc(100dvh - 90px - 20px - 32px - 32px - 70px);
        height: fit-content;
      }
    }

    
  @media screen and (max-width: 1024px) {

    .date-container-custom .react-datepicker-wrapper{
      width: 100% !important;
    }

  }

  .jurisdiction-cost-approval-container{
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 530px){
    
    .jurisdiction-cost-approval-container{
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  .custom-modal{
    outline: none !important;
    border: none !important;
  }
  .custom-modal:focus {
    outline: none !important;
    border: none !important;
  }

  .bell-menu-layout{
    position: absolute;
    border: 2px solid #FBFAFB;
    height: calc(100dvh - 90px - 17px - 10px);
    border-radius: 20px;
    width: calc(100% - 25px);
    margin: 0px 0px 0px 10px;
    padding: 8px;
    /* margin: 0px 100px 0px 0px; */
  }


  @media screen and (max-width: 640px) {

    .firmfox-logo-mobile{
      width: 157px;
      height: 32px;
    }

    .referral-form-width{
      width: 80%;
      max-width: 320px;
    }
    
  }

  @media screen and (max-width: 640px) and (min-width: 400px) {
    
    .sign-in-form{
      min-width: 300px;
    }
    
  }

  .drawer-custom{
    position: absolute;
    z-index: 100;
    width: calc(100% - 20px);
    min-height: calc(100dvh - 75px);
    margin: 0px 10px;
    border-radius: 20px;
  }

  .react-datepicker{
    margin-left: 55px;
  }

  @media screen and (min-width:1280px) and (max-width: 2000px) {

    .office-space-field-container{
      width: 180px !important;
    }

    .jurisdiction-type-field-container{
      width: 180px !important;
    }

    .visa-req-field-container{
      width: calc(100% - 300px) !important;
      min-width: 550px;
    }
    
  }

  @media screen and (min-width: 1024px) {

    .visa-req-field-container{
      min-width: 505px !important;
    }
    
  }

  @media screen and (max-width: 640px) {

    .field-justify{
      display: flex;
      justify-content: space-between;
    }
    
  }

  .visa-req-form-container{
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 900px) {

    .visa-req-form-container{
      flex-direction: column;
    }
    
  }